vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Mesoterapia capilar</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt=" mesoterapia inyectada" src="../../assets/29.mesoterapia-capilar-dentro.svg" />
            </div>
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt=" mesoterapia inyectada capilar"
                    src="../../assets/31.mesoterapia-capilar-home.svg" />
            </div>
            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>



                        <p>
                            Un asunto estético que preocupa a los hombres principalmente, pero también a algunas mujeres es
                            la alopecia. Suele afectar a los hombres porque está muy relacionada con la testosterona, pero,
                            cuando afecta a una mujer resulta bastante más llamativo por no ser tan frecuente.
                            Afortunadamente tenemos soluciones a la caída del cabello, en Centro Médico Beatriz Ayllón
                            optamos por la mesoterapia capilar por sus buenos resultados y por ser un tratamiento no
                            invasivo.
                        </p>

                        <h2>¿En qué consiste el tratamiento?</h2>

                        <p>
                            Se aplica mediante microinyecciones en el cuero cabelludo. No se necesita anestesia pues es
                            prácticamente indoloro, si tienes la piel muy sensible quizá lo notes un poco pero no hablamos
                            de dolor. Otra ventaja es que esta terapia puede realizarse en cualquier época del año.
                        </p>

                        <p>
                            El contenido de las inyecciones varía de una persona a otra. Se trata de una fórmula creada en
                            exclusiva para cada paciente dependiendo, principalmente de su tipo de piel, aunque se tienen en
                            cuenta otros factores. Las inyecciones para la regeneración capilar suelen tener vitaminas,
                            aminoácidos y fármacos para bioestimular las células de la raíz del folículo piloso.
                        </p>

                        <h2>Beneficios de la mesoterapia capilar</h2>

                        <ul>
                            <li>Estimulación del crecimiento capilar: Contiene factores de crecimiento que promueven la
                                regeneración de los folículos pilosos.</li>
                            <li>Fortalecimiento del cabello: El tratamiento ayuda a mejorar la calidad y resistencia del
                                cabello existente.</li>
                            <li>Reducción de la caída del cabello: La mesoterapia capilar puede disminuir la pérdida de
                                cabello y prevenir su adelgazamiento.</li>
                            <li>Resultados duraderos.</li>
                        </ul>

                        <h2>¿El resultado es igual de bueno en hombres y mujeres?</h2>

                        <p>
                            La mesoterapia capilar es eficaz en hombres y mujeres si bien, en mujeres tarda un poco más en
                            hacer efecto. Es muy importante la constancia en las sesiones. Antes de comenzar te informaremos
                            de cuántas sesiones necesitarás y la frecuencia de las mismas. Los resultados no son inmediatos,
                            son consecuencia de la constancia. Los resultados visibles, varían de un paciente a otro,
                            generalmente se notan a partir del tercer mes de tratamiento.
                        </p>



                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'MesoterapiaVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>